$(function () {

    /****************************************
     * CM
     ****************************************/

    (function () {
        $(".information-thumbnails li a").on('click', function (e) {
            var this_src = $(this).attr('href');
            var this_title = $(this).find('#cm_text_sub').text();

            $("#video")[0].src = this_src + "?autoplay=1";
            $("#cm_title").text(this_title);
            console.log(this_title);
            e.preventDefault();

        });

    }).call(this);
});